import { SendOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Typography,
} from 'antd';
import React, { useCallback, useState } from 'react';
import { sendGetLogsCommandMutation } from '../../constants';

const { Text } = Typography;

function SendGetLogsCommand({ joonDevice }) {
  const [form] = Form.useForm();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [sendGetLogsCommand] = useMutation(sendGetLogsCommandMutation);

  const handleSendCommand = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        await sendGetLogsCommand({
          variables: {
            cmd: {
              deviceId: joonDevice._id,
              ...values,
            },
          },
        });
        message.success('Get logs command sent');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, sendGetLogsCommand],
  );

  const formItemStyle = {
    marginRight: 16,
    marginBottom: 8,
  };

  return (
    <Card
      title="Upload Device Logs"
      className="upload-logs-cmd"
      style={{ marginBottom: 16 }}
    >
      <Form
        onFinish={handleSendCommand}
        layout="vertical"
        style={{ maxWidth: 600 }}
        form={form}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Form.Item
            name="includeApp"
            valuePropName="checked"
            initialValue={true}
            extra="Theora Connect's primary log files"
            style={formItemStyle}
          >
            <Checkbox>Include App Logs</Checkbox>
          </Form.Item>
          <Form.Item
            name="includeLogcat"
            valuePropName="checked"
            initialValue={false}
            extra="Only captures the current log buffer"
            style={formItemStyle}
          >
            <Checkbox>Include Android System Logs</Checkbox>
          </Form.Item>
          <Form.Item
            name="includeFallDetect"
            valuePropName="checked"
            initialValue={false}
            extra="Fall Detection's primary log files"
            style={formItemStyle}
          >
            <Checkbox>Include Fall Detect App Logs</Checkbox>
          </Form.Item>
          <Form.Item
            name="includeSaved"
            valuePropName="checked"
            initialValue={false}
            extra="This may comsume a lot of mobile data (>200MB)."
            style={formItemStyle}
          >
            <Checkbox>
              Include System Logs Captured by &quot;Save Logs&quot;
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="maxFiles"
            initialValue={0}
            extra="Max # of files in each category, 0 means send it all!"
            style={formItemStyle}
          >
            <InputNumber
              disabled={loading}
              min={0}
              step={1}
              style={{ width: 200 }}
            />
          </Form.Item>
        </div>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button icon={<SendOutlined />} loading={loading} htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <style jsx global>{`
        .upload-logs-cmd .ant-checkbox-inner,
        .upload-logs-cmd .ant-checkbox-input {
          transform: scale(0.75);
        }
        .upload-logs-cmd .ant-input {
          padding: 4px 8px;
        }
      `}</style>
    </Card>
  );
}

export default SendGetLogsCommand;
