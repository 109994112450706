import { SendOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Card, Form, Input, message, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

const { Text, Paragraph } = Typography;

export const sendRegisterCommandMutation = gql`
  mutation SendRegisterCommand($cmd: SendRegisterCommandInput!) {
    sendRegisterCommand(cmd: $cmd)
  }
`;

export const sendUnregisterCommandMutation = gql`
  mutation SendUnregisterCommand($cmd: SendUnregisterCommandInput!) {
    sendUnregisterCommand(cmd: $cmd)
  }
`;

function SendRegisterCommand({ joonDevice }) {
  const [form] = Form.useForm();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [sendRegisterCommand] = useMutation(sendRegisterCommandMutation);
  const [sendUnregisterCommand] = useMutation(sendUnregisterCommandMutation);

  const handleSendRegister = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        await sendRegisterCommand({
          variables: {
            cmd: {
              deviceId: joonDevice._id,
            },
          },
        });
        message.success('Registration command sent');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, sendRegisterCommand],
  );

  const handleSendUnregister = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        await sendUnregisterCommand({
          variables: {
            cmd: {
              deviceId: joonDevice._id,
            },
          },
        });
        message.success('Unregister command sent');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, sendUnregisterCommand],
  );

  return joonDevice.ownerId ? (
    <Card
      title="Resend Registration"
      className="config-cmd"
      style={{ marginBottom: 16 }}
    >
      <Form
        onFinish={handleSendRegister}
        layout="vertical"
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Paragraph>
          {`Owner ID: ${joonDevice.ownerId}`}
          <br />
          {`Owner Name: ${joonDevice.ownerName}`}
          <br />
          {`Wearer Name ${joonDevice.wearerName}`}
        </Paragraph>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button icon={<SendOutlined />} loading={loading} htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
    </Card>
  ) : (
    <Card
      title="Resend Unregister Command"
      className="config-cmd"
      style={{ marginBottom: 16 }}
    >
      <Form
        onFinish={handleSendUnregister}
        layout="vertical"
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button icon={<SendOutlined />} loading={loading} htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
    </Card>
  );
}

export default SendRegisterCommand;
