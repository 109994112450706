import { SendOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Form, Input, message, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { envNameQuery, switchEnvironmentMutation } from '../../constants';

const { Text } = Typography;

function SwitchToEnvCommand({ joonDevice, switchTo }) {
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [switchEnvironment] = useMutation(switchEnvironmentMutation);

  const handleSendCommand = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        await switchEnvironment({
          variables: {
            deviceId: joonDevice._id,
            envName: switchTo,
          },
        });
        message.success('Switch environment command sent');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, switchEnvironment, switchTo],
  );

  const switchToName = {
    dev: 'Development',
    prod: 'Production',
    local: 'Local',
  }[switchTo];

  return (
    <Card
      title={`Move to ${switchToName} Environment`}
      className="config-cmd"
      style={{ marginBottom: 16 }}
    >
      <Form
        onFinish={handleSendCommand}
        layout="vertical"
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button icon={<SendOutlined />} loading={loading} htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
    </Card>
  );
}

function SendSwitchEnvCommand({ joonDevice }) {
  const {
    loading: loadingEnv,
    error: errorEnv,
    data: envData,
    refetch: refetchEnv,
  } = useQuery(envNameQuery, {
    variables: {},
    fetchPolicy: 'network-only',
  });
  const envName = envData?.envName;

  return (
    <>
      {envName === 'dev' && (
        <>
          <SwitchToEnvCommand joonDevice={joonDevice} switchTo="prod" />
          <SwitchToEnvCommand joonDevice={joonDevice} switchTo="local" />
        </>
      )}
      {envName === 'prod' && (
        <>
          <SwitchToEnvCommand joonDevice={joonDevice} switchTo="dev" />
        </>
      )}
      {envName === 'local' && (
        <>
          <SwitchToEnvCommand joonDevice={joonDevice} switchTo="dev" />
          <SwitchToEnvCommand joonDevice={joonDevice} switchTo="prod" />
        </>
      )}
    </>
  );
}

export default SendSwitchEnvCommand;
