import { mkConfig, generateCsv, download } from 'export-to-csv';
import { notNullOrUndefined } from '../../../shared/utils';

export function getArrayFromUrlFilters(urlFilters, name) {
  let nextV;
  if (notNullOrUndefined(urlFilters[name])) {
    const v = urlFilters[name];
    if (!Array.isArray(v)) {
      nextV = [v.toString()];
    } else {
      nextV = v;
    }
  } else {
    nextV = [];
  }
  return nextV;
}

export function exportDevicesToCsv(devices) {
  const data = [];
  // const dateFormatter = new Intl.DateTimeFormat(locale, {
  //   dateStyle: 'short',
  // });
  devices.forEach((report) => {
    data.push({
      'Tenant ID': report.tenantId,
      'Tenant Name': report.tenantName,
      IMEI: report.imei,
      'Serial No.': report.serialNo,
      ICCID: report.iccid,
      'Call Center ID': report.callCenterId,
      'Phone Number': report.msisdn,
      'Firmware Version': report.fwVer,
      'App Version': report.appVer,
    });
  });
  const options = mkConfig({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    useTextFile: false,
    useBom: true,
    filename: `device-report-export`,
    showColumnHeaders: true,
    columnHeaders: [
      'Tenant ID',
      'Tenant Name',
      'IMEI',
      'Serial No.',
      'ICCID',
      'Call Center ID',
      'Phone Number',
      'Firmware Version',
      'App Version',
    ],
  });

  const csv = generateCsv(options)(data);
  download(options)(csv);
}
