import { SendOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Typography, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { requestRebootMutation } from '../../constants';

const { Text } = Typography;

function SendRebootCommand({ joonDevice }) {
  const [form] = Form.useForm();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [requestReboot] = useMutation(requestRebootMutation);

  const handleSendCommand = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        await requestReboot({
          variables: {
            _id: joonDevice._id,
          },
        });
        message.success('Reboot requested');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, requestReboot],
  );

  return (
    <Card
      title="Request Reboot"
      className="config-cmd"
      style={{ marginBottom: 16 }}
    >
      <Form
        onFinish={handleSendCommand}
        layout="vertical"
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button icon={<SendOutlined />} loading={loading} htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
    </Card>
  );
}

export default SendRebootCommand;
